import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header as={"h1"} className={"about-us-header"}>
                  ABOUT US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <p>
                        Our mission is to be the cherished heart of our
                        community, fostering connections through exceptional
                        hospitality, quality offerings, and a celebration of
                        local culture. With integrity and a commitment to
                        continuous improvement, we create memorable experiences,
                        support important causes, and enrich the lives of our
                        patrons while strengthening our neighborhood.
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <Header as={"h1"} textAlign={"center"}>
            OUR GUIDING PRINCIPLES
          </Header>
          <Header as={"h2"} textAlign={"center"} color={"yellow"}>
            THE HEART AND SOUL OF CARNEY'S
          </Header>
          <Grid
            stackable={true}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
            column={"2"}
          >
            <Grid.Row stretched={true}>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/community.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      COMMUNITY
                    </Header>
                    <div>
                      Embrace diversity and create a welcoming environment.
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/hospitality.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      HOSPITALITY
                    </Header>
                    <div>
                      Provide exceptional customer service and a hospitable
                      atmosphere.
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched={true}>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/quality.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      QUALITY
                    </Header>
                    <div>
                      Deliver high-quality food, beverages, and a comfortable
                      setting.
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/authenticity.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      AUTHENTICITY
                    </Header>
                    <div>Celebrate local culture, heritage, and flavors.</div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched={true}>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/social_hub.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      SOCIAL HUB
                    </Header>
                    <div>
                      Foster connections and host events that bring people
                      together.
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/responsiveness.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      RESPONSIVENESS
                    </Header>
                    <div>Listen to feedback and continuously improve.</div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched={true}>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/integrity.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      INTEGRITY
                    </Header>
                    <div>Operate with honesty, fairness, and respect.</div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={6} textAlign={"left"}>
                <div className={"position-flex"}>
                  <div className={"logo-image"}>
                    <Image
                      size={"tiny"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f1adaffe-7d30-4939-893c-0d18daf7dbc9/support.png"
                      }
                    />
                  </div>
                  <div className={"content"}>
                    <Header as={"h2"} textAlign={"left"} color={"yellow"}>
                      SUPPORT
                    </Header>
                    <div>Contribute to local causes and initiatives</div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
